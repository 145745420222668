<template>
  <v-dialog v-model="userEmailEditDialog" scrollable max-width="900px">
    <v-card v-if="userEmailEditDialog">
      <v-card-title class="headline">
        <h1>
          Editar usuário
        </h1>
        <v-btn class="close-modal" @click="close">
          <i class="fal fa-times"></i>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text style="height: 550px">
        <v-layout w100 d-flex>
          <div>
            <v-card-text style="height: 470px">
              <v-layout pa-4 wrap class="list-scroll">
                <v-flex xs12>
                  <InputText
                    ref="cpfInput"
                    type="tel"
                    v-model="user.cpf"
                    textLabel="CPF"
                    mask="###.###.###-##"
                  />
                  <InputText
                    ref="nameInput"
                    type="text"
                    v-model="user.personName"
                    textLabel="Nome"
                  />
                  <InputText
                    ref="emailInput"
                    type="text"
                    v-model="user.email"
                    textLabel="E-Mail"
                    :validation="validateEmail"
                    invalidInputMessage="Digite um e-mail"
                  />
                </v-flex>
                <label class="pt-4">Endereço:</label>
                <v-flex xs12>
                  <InputText
                    ref="postalCodeInput"
                    type="text"
                    v-model="user.address.postalCode"
                    textLabel="CEP"
                    mask="#####-###"
                  />
                  <InputText
                    ref="streetInput"
                    type="text"
                    v-model="user.address.street"
                    textLabel="Rua"
                  />
                  <InputText
                    ref="numberInput"
                    type="text"
                    v-model="user.address.number"
                    textLabel="Número"
                  />
                  <InputText
                    ref="neighborhoodInput"
                    type="text"
                    v-model="user.address.neighborhood"
                    textLabel="Bairro"
                  />
                  <InputText
                    ref="complementInput"
                    type="text"
                    v-model="user.address.complement"
                    textLabel="Complemento"
                  />
                  <InputText
                    ref="cityInput"
                    type="text"
                    v-model="user.address.city"
                    textLabel="Cidade"
                  />
                  <InputText
                    ref="ufInput"
                    type="text"
                    v-model="user.address.uf"
                    textLabel="Estado"
                  />
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions class="pa-0">
              <div class="footer-modal-atividades">
                <v-btn
                  flat
                  round
                  color="white"
                  class="btn btn-default px-5"
                  large
                  @click="close"
                  >Cancelar</v-btn
                >
                <v-btn
                  flat
                  round
                  color="white"
                  class="btn btn-primary px-5"
                  large
                  @click="isValid"
                  >Salvar</v-btn
                >
              </div>
            </v-card-actions>
          </div>
        </v-layout>
      </v-card-text>
      <v-divider></v-divider>
    </v-card>
  </v-dialog>
</template>

  
<script type="plain/text">
import UserPerson from "@/scripts/models/userPerson.model";
import UserService from "@/scripts/services/user.service";
import CompanyGroupService from "@/scripts/services/companyGroup.service";
import PersonService from "@/scripts/services/person.service";
import InstitutionService from "@/scripts/services/institution.service";
import CouncilService from "@/scripts/services/council.service";
import PermissionService from "@/scripts/services/permission.service";
import { mapGetters } from "vuex";
import Entity from "@/scripts/models/entity.model";
import Permissions from "@/scripts/models/enums/permissions.enum";
import ValidatableComponent from "@/components/base/ValidatableComponent.vue";
import CommonHelper from "@/scripts/helpers/common.helper";

import {
  EntityType,
  EntityTypeList,
} from "@/scripts/models/enums/entityType.enum.js";
import {
  UserOccupation,
  UserOccupationList,
} from "@/scripts/models/enums/userOccupation.enum.js";

export default {
  extends: ValidatableComponent,
  props: ["modal-title", "entityId"],
  data() {
    return {
      userEmailEditDialog: false,
      signedUserEntityType: null,
      userPerson: new UserPerson(),
      userService: new UserService(),
      personService: new PersonService(),
      user: {
        personName: '',
        email: '',
        cpf: '',
        address: {
          street: '',
          number: '',
          neighborhood: '',
          complement: '',
          city: '',
          uf: '',
          postalCode: ''
        }
      },
      originalPerson: {},
      showMessage: false,
      snackbarMessage: "",
      companyGroupService: new CompanyGroupService(),
      institutionService: new InstitutionService(),
      councilService: new CouncilService(),
      permissionService: new PermissionService(),
      currentEntity: new Entity(),
      entity: new Entity(),
      originalUserPerson: null,
      selectedCompanyGroup: null,
      selectedEntityType: null,
      editingMode: false,
      userHasPermissions: false,
      instituionList: [],
      companyGroupList: [],
      roles: [],
      userPersonFound: null,
      listPermissions: null,
      entityType: EntityType,
      entityTypeList: EntityTypeList,
      userOccupation: UserOccupation,
      userOccupationList: UserOccupationList,
      hasPresidentAtEntity: false,
      hasRepresentativeAtEntity: false,
      isUserFromAUC: false,
      loggedUser: { }
    };
  },
  computed: {
    ...mapGetters(["hasPermission"]),

    enabledPermissionList() {
      return this.listPermissions.filter(function (item) {
        return item.canShow;
      });
    },
  },
  created() {
    this.getPerson();
    this.showValidationFailMessageMethod = this.showFailMessage;
    this.onValidationSuccessMethod = this.validationSuccess;
    this.validationMethod = this.validate;
    this.permissionService.listAll().then(
      function (data) {
        let permissionsToShow = [
          Permissions.ConciliationRH,
          Permissions.CashFlow,
          Permissions.CanSignReceipt,
        ];
        data.map(function (item) {
          item.canShow = false;
          if (
            permissionsToShow.includes(item.id) &&
            item.id != Permissions.CanSignReceipt
          ) {
            item.canShow = true;
          }
        });
        this.listPermissions = data.filter((item) =>
          permissionsToShow.includes(item.id)
        );
      }.bind(this)
    );
    this.userService.getEntityById(this.entityId).then(
      function (data) {
        if (!data) return;
        this.currentEntity = data;
        if (this.currentEntity.id != 0) {
          if (this.currentEntity.type == EntityType.AUC) {
            this.companyGroupService.listAllCompanyGroup(
              this.listAllCompanyGroupCallback
            );
            this.institutionService.listAll(this.listAllInstituionCallback);
            this.councilService.listAll(this.listAllCouncilCallback);
          } else {
            this.selectedEntityType = this.currentEntity.type;
          }
        }
      }.bind(this)
    );
  },
  methods: {
    save() {
      console.log('USER', this.user)
        
        this.originalPerson.name = this.user.personName
        this.originalPerson.cpf = this.user.cpf
        this.originalPerson.email = this.user.email;

        this.originalPerson.address = {}
        this.originalPerson.address.city = this.user.address.city;
        this.originalPerson.address.complement = this.user.address.complement;
        this.originalPerson.address.neighborhood = this.user.address.neighborhood;
        this.originalPerson.address.number = this.user.address.number;
        this.originalPerson.address.street = this.user.address.street;
        this.originalPerson.address.state = this.user.address.uf;
        this.originalPerson.address.postalCode = this.user.address.postalCode;

        if (CommonHelper.validateCPF(this.user.cpf) === false) {
          this.$store.commit("SET_SNACKBAR_MESSAGE", "CPF inválido.");
          return;
        }

        if (this.user.email && this.isEmailValid(this.user.email) === false) {
          this.$store.commit("SET_SNACKBAR_MESSAGE", "E-mail inválido.");
          return;
        }

        this.personService.updatePerson(this.originalPerson).then(
          function (data) {
            this.$emit("refresh");
            this.close();
          }.bind(this)
        );

        this.$store.commit("SET_SNACKBAR_SUCCESS_MESSAGE", "Usuário atualizado com sucesso!");
    },
    async getPerson() {
      if (this.originalUserPerson && this.originalUserPerson.cpf)
      this.originalPerson = await this.personService.findPersonByCPF(this.originalUserPerson.cpf);
    },
    emailIsEqual() {  
      if (!this.user.email || this.isEmailValid(this.user.email) === false) {
        this.snackbarMessage = "É necessário digitar um e-mail válido.";
        this.showMessage = true;
        return false;
      }

      return true;
    },
    async open(item, signedUserEntityType) {
      this.signedUserEntityType = signedUserEntityType;
      this.userEmailEditDialog = true;
      this.originalUserPerson = item;

      await this.getPerson()

      if (item.personId) this.editingMode = true;
      else if (this.currentEntity.type != EntityType.AUC) {
        this.originalUserPerson.currentUserRole.entityId = this.currentEntity.id;
      }
      this.copy();
      this.personService.getLoggedPersonUser(this.entityId).then(
			function(data) {
        debugger
				if (data) {
          this.isUserFromAUC = data.entitiesOccupation.some(x => x.entityId === 6);
        }
			}.bind(this)
		);

    
    },
    close() {
      this.userEmailEditDialog = false;
      this.selectedCompanyGroup = null;
      this.userHasPermissions = false;
      this.userAlreadyExists = false;
      this.editingMode = false;
      //this.copy(new UserPerson(), this.userPerson);
    },
    copy() {
      this.user.cpf = this.originalUserPerson.cpf;
      this.user.personName = this.originalPerson.name;
      this.user.email = this.originalUserPerson.email;
      this.user.address.city = this.originalPerson.address.city;
      this.user.address.complement = this.originalPerson.address.complement;
      this.user.address.neighborhood = this.originalPerson.address.neighborhood;
      this.user.address.number = this.originalPerson.address.number;
      this.user.address.street = this.originalPerson.address.street;
      this.user.address.uf = this.originalPerson.address.state;
      this.user.address.postalCode = this.originalPerson.address.postalCode;
    },
    validateCPF() {
      if (this.userPerson.cpf) {
        return CommonHelper.validateCPF(this.userPerson.cpf);
      }
    },
    validateEmail() {
      return CommonHelper.validateEmail(this.user.email);
    },
    isEmailValid(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    showFailMessage(invalidElements) {
      if (invalidElements.length >= 1) {
        this.$store.commit(
          "SET_SNACKBAR_MESSAGE",
          "Você não preencheu todos os campos"
        );
      }
    },
    validate(performMethods) {
      let valid = this.validateComponents(performMethods);
      if (!valid) return false;
      return true;
    },
    validationSuccess() {
      this.save();
    },
  },
};
</script>